<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div class="row" style="height:100%">
            <div class="list-area col-md-7">
                <!-- Header Title -->
                <div class="row">
                    <header-box :title="`${$t('관제점 구성')} > ${$t('관제점 관리')}`" />
                </div>
                <!-- Search Condition  -->
                <div style="height: calc(100% - 40px)">
                    <point-search ref="point-search" :tableMaxHeight="'70vh'" :optionTableHeight="'70vh'" 
                        @select-point="handleItemClick"
                        @clear-detail="clearDetail" />
                </div>
            </div>
            <!-- Detail -->
            <div class="detail-area col-md-5">
                <point-detail ref="point-detail" :title="''" :ptIdx="selectedPtIdx" 
                @data-updated="handleDataUpdated" @closed="handleClosed" />
            </div>
        </div>
    </div>

    <!-- <div class="col-md-12" style="height: 87vh">
        <header-box :title="`${$t('관제점 구성')} > ${$t('관제점 관리')}`" />
        <div class="row flex-fill left-area" style="height:100%; flex-wrap:nowrap;">
            <div id="list" class="col col-md-7">
                <point-search :tableMaxHeight="'70vh'" :optionTableHeight="'70vh'" @select-point="handleItemClick"
                    @clear-detail="clearDetail" />
            </div>
            <div id="detail" class="col d-none d-lg-block right-area">
                <point-detail ref="point-detail" :title="'관제점 정보'" @save="handleSaveData" />
            </div>
        </div>
    </div> -->
</template>

<script>

import headerBox from "@views/component/headerBox/analysis/Header.vue";
import PointSearch from "./PointSearch.vue";
import PointDetail from "./PointDetail.vue"

import backEndApi from "@src/api/backEndApi";
import moment from "moment"

export default {
    components: {
        headerBox,
        PointSearch,
        PointDetail
    },
    props: {},
    data() {
        return {
            selectedPtIdx: null,
            controls: [
                { event: 'regist', label: '추가', class: 'btn-warning', },
            ],
            columns: [ // 테이블 column
                {
                    label: this.$t("일련번호"),
                    field: "ptIdx",
                    type: "text",
                },
                {
                    label: this.$t("관제점 그룹"),
                    field: "ptGroup",
                    type: "text"
                },
                {
                    label: this.$t("관제점 주소"),
                    field: "rowTitle",
                    width: "250px",
                    type: "text",
                    compositionFields: ['ptAddr', 'ptName'],
                },
                {
                    label: this.$t("상태값"),
                    field: "ptVal",
                    type: "text",
                },
                {
                    label: this.$t("물리/가상"),
                    field: "isVirtual",
                    type: "text",
                },
                {
                    label: this.$t("단위"),
                    field: "unitSmallCode",
                    type: "text",
                },
                {
                    label: this.$t("에너지원"),
                    field: "energySourceCode",
                    type: "text",
                },
                {
                    label: this.$t("소비용도"),
                    field: "itemUseageCode",
                    type: "text",
                },
                {
                    label: this.$t("사용여부"),
                    field: "enabled",
                    width: "100px",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: 'isVirtual',
                    codes: this.$store.state.commonCodes.isVirtual,
                },
                {
                    field: 'unitSmallCode',
                    codes: this.$store.state.units,
                },
                {
                    field: 'energySourceCode',
                    codes: this.$store.state.commonCodes.energySourceCode,
                },
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                }
            ],
            tableStyleClass: 'custom-table',
            // pointInfos: null,
        }
    },
    computed: {},
    async created() {
        // await this.getDataList();
    },
    mounted() {},
    methods: {
        async handleListButtonClick(event, options) {
            console.log('event: ', event);
            const that = this;

            console.log('handleListButtonClick:', event, options);

            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                // case 'delete':
                //     await that.deleteChecked();
                //     break;

                // case 'refresh':
                //     await that.getDataList(event);
                //     break;

                // case 'status':
                //     await that.changeStatusChecked(options);
                //     break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }

        },
        async handleItemClick(selected) {
            console.log('PointMgmt handleItemClick. ', selected);

            const confirm = await this.$refs['point-detail'].notifyCancelEdit();
            if (confirm) {
                let result = await backEndApi.pointInfo.getPointDetailNew(selected.ptIdx);
                // if (result.status == 200) await this.$refs['point-detail'].setData(result.data);

                if (result.status == 200) {
                    result.data.regDate = moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss")
                    result.data.modDate = moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss")
    
                    this.selectedPtIdx = selected.ptIdx;
                }
            }
        },      
        async registNew() {
            console.log('PointMgmt registNew() is called ');

            if (this.$refs['point-detail']) {
                const comfirm = await this.$refs['point-detail'].notifyCancelEdit()
                if (comfirm) {
                    this.$refs['point-detail'].newData();
                }
            }
        },
        async handleClosed(ptIdx) {
            console.log('PointMgmt handleClosed() is called. ', ptIdx);
            this.selectedPtIdx = null;
        },        
        async handleDataUpdated(data) {
            console.log('PointMgmt handleDataUpdated is called. ', data);
            await this.$refs['point-search'].searchPointList();
        },
        clearDetail() {
            console.log('PointMgmt clearDetail() is called')
            // this.$refs['point-detail'].clearData();
        },
    },
}

</script>

<style scoped>

.ld-container {
    height: 90vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    padding: 10px 20px 10px 20px;
}

.ld-container .list-area {
    height: 100%;
    overflow: hidden;
    padding: 10px;
    /* border-right: 1px solid #ececec; */
}

.ld-container .detail-area {
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-wrap: nowrap; */
    /* flex: 1; */

    height: 100%;
    margin-right: 00px;
    padding: 0px 10px 0px 10px;
    border-top: 1px solid #f4f4f4;
    border-right: 2px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    border-left: 1px solid #f4f4f4;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.custom-table table  td.left-align-wrap {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    text-align: left;
}

</style>